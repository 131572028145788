import Image from "@/shared/components/Image/new"
import { loader } from "@/shared/functions/getImagesFunctions";
import styles from "./styles.module.css";
import { formatAuthorNames } from '@/shared/functions/formatAthorNames';
import { EnVivo } from "@/shared/components/enVivo";

const Destacada = (props) => {
    const { title, author, summary, url, image, hasBackground,metadata} = props;
    let authorsList = []
    if (author != undefined && author?.length) {
		const authorName = formatAuthorNames(author[0].name);
		author.length > 1 ? author.slice(0, 2).map(element => {
			
            const elementName = formatAuthorNames(element.name);
            return authorsList.push(element.slug ? `<a href=/autor/${element.slug}>${elementName}</a>` : elementName)

        }) 
        
        : authorsList.push(author[0].slug ? `<a href=/autor/${author[0].slug}>${authorName}</a>` : authorName)
	}
    const parsedAuthors = authorsList.join(' y ');
   
    const noTagsHtml = summary && summary.length && summary.replace(/<[^>]+>/g, '').replace(/&nbsp[;]?/ig, ' ');
    return (
        <>
            <a href={url} className={`${styles["destacada-container"]}`}>
                <div className={`${styles["img-container"]}`}>
                    <Image
                        src={image}
                        alt={title}
                        width={630}
                        height={420}
                        loader={loader}
                        className={styles['img']}
                    />
                     {metadata && metadata.typeWeb && metadata.typeWeb === "live_blog" && <EnVivo data-testid='envivo'/>}
                </div> 
                {authorsList && (
                    <div className={`${styles["destacada-container-text"]}`}>
                        {title != "" && (
                            <h1 
                                className={`${styles["destacada-title"]}`} 
                                dangerouslySetInnerHTML={{ __html: title }} 
                            />
                        )}
                        
                        {summary != "" && (
                            <div className={
                                hasBackground
                                    ? `${styles["background"]} ${styles["destacada-summary"]}`
                                    : `${styles["destacada-summary"]}`}
                            >
                                {noTagsHtml}
                            </div>
                        )}
                    </div>
                )}
            </a>
            {parsedAuthors && (
                <div
                    className={`${styles["destacada-autor"]}`} 
                    dangerouslySetInnerHTML={{ 
                        __html: 'Por ' + parsedAuthors 
                    }} 
                />
            )}
        </>
    );
};
export default Destacada;
